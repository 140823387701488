<template>
  <div id="marker-map">
    <gmap-custom-marker :marker="marker">
      <div class="marker-amount" v-on:click="redirectToDetails()">
        {{ amountToShow }}
      </div>
      <div class="marker my-auto">
        <img src="@/assets/img/icons/marker.png" alt="" width="30" />
      </div>
    </gmap-custom-marker>
  </div>
</template>

<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
  name: "MarkerMap",
  components: {
    "gmap-custom-marker": GmapCustomMarker,
  },
  data() {
    return {
      lat: this.latitud,
      lng: this.longitud,
      price: this.amount,
    };
  },
  props: { latitud: Number, longitud: Number, amount: String, data: Object },
  computed: {
    marker() {
      return { lat: this.latitud, lng: this.longitud };
    },
    amountToShow() {
      return this.price;
    },
  },
  methods: {
    redirectToDetails() {
      var developmentId = this.data.developmentId;
      let path = "details?id=" + developmentId;

      let route = this.$router.resolve(path);
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/global.css";
.marker {
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  background-color: transparent;
  padding: 3px;
  color: white;
  border-radius: 7px;
  text-align: center;
}
.marker:hover {
  background-color: transparent;
  color: white;
}
.marker-selected {
  font-weight: bold;
  font-size: 15px;
  background-color: transparent;
  padding: 3px;
  color: white;
  border-radius: 10px;
}
.marker-amount {
  background-color: white;
  color: var(--main-color);
  font-size: 1rem;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 8px;
  font-weight: bold;
  border-radius: 10px;
}
.marker-amount:hover {
  font-size: 1.2rem;
  color: white;
  background-color: var(--main-color);
}
</style>
