import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/list",
    name: "List",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/List.vue"),
  },
  {
    path: "/details",
    name: "Details",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Details.vue"),
  },
  {
    path: "/favorites",
    name: "Favorites",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Favorites.vue"),
  },
  {
    path: "/calculator",
    name: "Calculator",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Calculator.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Blog.vue"),
  },
  {
    path: "/blog1",
    name: "Blog1",
    component: () => import("../views/blogs/Entry1.vue"),
  },
  {
    path: "/blog2",
    name: "Blog2",
    component: () => import("../views/blogs/Entry2.vue"),
  },
  {
    path: "/blog3",
    name: "Blog3",
    component: () => import("../views/blogs/Entry3.vue"),
  },
  {
    path: "/blog4",
    name: "Blog4",
    component: () => import("../views/blogs/Entry4.vue"),
  },
  {
    path: "/blog5",
    name: "Blog5",
    component: () => import("../views/blogs/Entry5.vue"),
  },
  {
    path: "/blog6",
    name: "Blog6",
    component: () => import("../views/blogs/Entry6.vue"),
  },
  {
    path: "/blog7",
    name: "Blog7",
    component: () => import("../views/blogs/Entry7.vue"),
  },
  {
    path: "/blog8",
    name: "Blog8",
    component: () => import("../views/blogs/Entry8.vue"),
  },
  {
    path: "/blog9",
    name: "Blog9",
    component: () => import("../views/blogs/Entry9.vue"),
  },
  {
    path: "/blog10",
    name: "Blog10",
    component: () => import("../views/blogs/Entry10.vue"),
  },
  {
    path: "/blog11",
    name: "Blog11",
    component: () => import("../views/blogs/Entry11.vue"),
  },
  {
    path: "/blog12",
    name: "Blog12",
    component: () => import("../views/blogs/Entry12.vue"),
  },
  {
    path: "/blog13",
    name: "Blog13",
    component: () => import("../views/blogs/Entry13.vue"),
  },
  {
    path: "/blog14",
    name: "Blog14",
    component: () => import("../views/blogs/Entry14.vue"),
  },
  {
    path: "/blog15",
    name: "Blog15",
    component: () => import("../views/blogs/Entry15.vue"),
  },
  {
    path: "/blog16",
    name: "Blog16",
    component: () => import("../views/blogs/Entry16.vue"),
  },
  {
    path: "/blog17",
    name: "Blog17",
    component: () => import("../views/blogs/Entry17.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
