import axios from "axios";

const URI = "https://quiero-techo-0000.azurewebsites.net/api/";
const paginationSize = 6;

var APIRequest = {
  async getHomeMapCoordinates() {
    try {
      var result = await axios
        .post(URI + "v2/getAllProperties")
        .catch((error) => {
          console.log(error);
        });

      return result["data"];
    } catch {
      console.log("Server error");
    }
  },
  async getDistrictsToSearch() {
    try {
      var result = await axios
        .post(URI + "v2/getAllDistricts")
        .catch((error) => {
          console.log(error);
        });

      return result["data"];
    } catch {
      console.log("Server error");
    }
  },
  async getSaleProperties(paginationIndex) {
    try {
      var result = await axios
        .post(URI + "v2/getSaleProperties", {
          indexPagination: paginationIndex,
          paginationSize: paginationSize,
        })
        .catch((error) => {
          console.log(error);
        });

      return result["data"];
    } catch {
      console.log("Server error");
    }
  },
  async getRentProperties(paginationIndex) {
    try {
      var result = await axios
        .post(URI + "v2/getRentProperties", {
          indexPagination: paginationIndex,
          paginationSize: paginationSize,
        })
        .catch((error) => {
          console.log(error);
        });

      return result["data"];
    } catch {
      console.log("Server error");
    }
  },
  async getPropertiesByQuery(
    districtIdQuery,
    minPrice,
    maxPrice,
    rentSaleQuery,
    roomsQuery,
    toiletsQuery,
    amenitiesQuery,
    indexPaginationQuery
  ) {
    try {
      var result = await axios
        .post(URI + "v2/queryProperties", {
          district: districtIdQuery,
          minPrice: minPrice,
          maxPrice: maxPrice,
          rentSale: rentSaleQuery,
          rooms: roomsQuery,
          toilets: toiletsQuery,
          amenities: amenitiesQuery,
          indexPagination: indexPaginationQuery,
          paginationSize: paginationSize,
        })
        .catch((error) => {
          console.log(error);
        });

      return result["data"];
    } catch {
      console.log("Server error");
    }
  },
  async getDevelopmentData(developmentId) {
    try {
      var result = await axios
        .post(URI + "v2/getDevelopmentData", {
          developmentId: developmentId
        })
        .catch((error) => {
          console.log(error);
        });

      return result["data"];
    } catch {
      console.log("Server error");
    }
  },
};

export default APIRequest;
