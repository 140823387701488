<template>
  <div id="map">
    <GmapMap :center="center" :zoom="12" style="width:100%;  height: 50vh;">
      <div v-for="i in this.allProperties" :key="i.developments.developmentId">
        <MarkerMap
          :latitud="i.coordinateX"
          :longitud="i.coordinateY"
          :amount="parseNumber(i.price)"
          :data="i.developments"
        />
      </div>
    </GmapMap>
  </div>
</template>

<script>
import MarkerMap from "@/components/map/Marker.vue";
import APIRequest from "@/helperjs/api.js";

export default {
  name: "GoogleMap",
  components: {
    MarkerMap,
  },
  data() {
    return {
      center: { lat: 22.140778543203673, lng: -100.95108221704429 },
      allProperties: [],
    };
  },
  beforeMount() {

    APIRequest.getHomeMapCoordinates()
    .then((result) => {
        this.allProperties = result["data"];
    });

  },
  methods: {
    parseNumber(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "MXN",
      });

      return formatter.format(amount);
    },
  },
};
</script>
