<template>
  <div class="home">
    <section id="home-land" v-bind:style="backImage">
      <div class="container" style="padding-top: 1rem">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center" style="font-weight: bold">
              ¡Encuentra tu próximo techo!
            </h1>
          </div>

          <div class="col-12 mt-3 mb-5">
            <h4
              class="text-center"
              style="font-weight: bolder; color: var(--main-color)"
            >
              Filtra, compara y agenda
            </h4>
          </div>

          <div class="col-12 text-center mt-3 pt-1 mb-2">
            <button
              class="mt-4 mr-lg-4 mr-0 newButton"
              v-on:click="RouteToSale()"
            >
              Comprar
            </button>
            <button class="mt-4 newButton" v-on:click="RouteToRent()">
              Rentar
            </button>
          </div>

          <div class="col-lg-6 offset-lg-3 col-12 pt-lg-4 pt-1">
            <div>
              <b-dropdown
                text="Busca por colonia, ciudad o alcaldía"
                block
                variant="primary"
                class="m-2"
                menu-class="w-100"
              >
                <div v-for="i in this.districts" :key="i.districtId">
                  <b-dropdown-item
                    v-on:click="SearchByWord(i.districtId, i.districtName)"
                    >{{ i.districtName }}</b-dropdown-item
                  >
                </div>
              </b-dropdown>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section>
      <div class="container pt-5">
        <div class="row">
          <div class="col-lg-4 col-12">
            <h5 class="pb-3" style="text-align: justify; font-weight: bold">
              Encuentra tu próximo techo en el mapa. Propiedades en distristas zonas de San Luis Potosí en renta y venta esperando por ti.
            </h5>

            <h5 class="mt-3">Elige tu próximo techo</h5>
          </div>

          <div class="col-lg-8 col-12 pt-lg-0 pt-5">
            <Map />
          </div>
        </div>
      </div>
    </section>

    <section class="background-home mt-5 pt-5" v-bind:style="howImage">
      <div class="container pt-5">
        <div class="row">
          <div class="col-12">
            <h2 class="text-center" style="font-weight: bold">
              ¿Cómo lo hacemos?
            </h2>
          </div>
          <div class="col-12">
            <h6
              class="text-center"
              style="font-weight: bolder; color: var(--main-color)"
            >
              Te representamos en la compra de tu próximo techo
            </h6>
          </div>

          <div class="col-12" id="how-container">
            <div class="container">
              <img
                class="points-up"
                src="../assets/col-logos/puntos_arriba.svg"
                alt=""
              />
              <img
                class="points-down"
                src="../assets/col-logos/puntos_abajo.svg"
                alt=""
              />

              <div class="row">
                <div class="col-md-4 col-12 p-md-3 p-3">
                  <div class="card-steps text-center pt-5 pl-5 pr-5">
                    <img src="../assets/img/icons/seleccion.svg" alt="" />
                    <h6 class="text-center pt-3">
                      Selecciona los techos que más te gustan
                    </h6>
                  </div>
                </div>

                <div class="col-md-4 col-12 p-md-3 p-3">
                  <div class="card-steps text-center pt-5 pl-5 pr-5">
                    <img src="../assets/img/icons/comparar.svg" alt="" />
                    <h6 class="text-center pt-3">
                      Compara los precios entre las opciones de tu interés
                    </h6>
                  </div>
                </div>

                <div class="col-md-4 col-12 p-md-3 p-3">
                  <div class="card-steps text-center pt-4 pl-5 pr-5">
                    <img src="../assets/img/icons/atencion.svg" alt="" />
                    <h6 class="text-center">
                      Agenda tu cita, un asesor inmobiliario te acompañará hasta
                      el último momento de tu compra o renta
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-8"></div>
          </div>

          <div class="col-12 text-center pt-5">
            <h5 style="font-weight: bold">¿Qué esperas para comenzar?</h5>
          </div>
          <div class="col-12 text-center">
            <h5 style="font-weight: bold">Encuentra tu techo</h5>
          </div>

          <div class="col-12 text-center pt-3">
            <button
              onclick="location.href='https://wa.me/525613739050'"
              type="button"
              class="mb-5"
              style="
                color: var(--white-color);
                font-size: 1rem;
                background-color: var(--main-color);
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 15px;
                padding-left: 15px;
                border-radius: 10px;
                border-color: transparent;
                font-weight: normal;
              "
            >
              Contáctanos
            </button>
          </div>
        </div>
      </div>
    </section>

    <section style="background-color: var(--main-color)">
      <div class="container pt-5 mb-5">
        <div class="row">
          <div class="col-12 pb-3">
            <h3 class="text-center" style="color: white; font-weight: bolder">
              ¿Tienes una propiedad?
            </h3>
          </div>
          <div class="col-12 pb-4">
            <h5 class="text-center" style="color: white">
              ¿Necesitas alquilarla o venderla?
            </h5>
          </div>
          <div class="col-12">
            <h5 class="text-center" style="color: white">
              Nosotros te encontramos inquilino o comprador en menos de 45 días.
            </h5>
          </div>
          <div class="col-12 pb-2">
            <h5 class="text-center" style="color: white">
            </h5>
          </div>
          <div class="col-12 text-center pt-5">
            <button
              onclick="location.href='https://wa.me/525613739050'"
              type="button"
              class="mb-5"
              style="
                color: var(--white-color);
                font-size: 1rem;
                background-color: var(--green-color);
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 15px;
                padding-left: 15px;
                border-radius: 10px;
                border-color: transparent;
                font-weight: bold;
              "
            >
              Contáctanos aquí
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="background-home" v-bind:style="recommendImage">
      <div class="container">
        <div class="row">
          <div class="col-12 mt-5 mb-4 text-center">
            <h3>A ellos ya los representamos</h3>
          </div>

          <div class="col-12">
            <carousel
              :nav="false"
              :dots="true"
              :items="3"
              :margin="20"
              :loop="true"
              :responsive="{
                0: { items: 1, nav: false },
                600: { items: 3, nav: false },
              }"
            >
              <div
                class="card text-center mr-4 ml-4"
                style="border-color: transparent"
              >
                <div class="container mt-2 mb-2">
                  <img
                    style="
                      border-radius: 50%;
                      width: 150px;
                      height: 150px;
                      object-fit: cover;
                    "
                    src="../assets/img/andre-rec.jpg"
                    alt="Avatar"
                    class="mx-auto"
                  />
                </div>
                <div class="container">
                  <p style="color: var(--green-color); font-weight: bold">
                    Manuel
                  </p>
                  <p style="color: var(--black-color); margin-bottom: 10px">
                    Inversionista
                  </p>
                  <p>
                    "El asesor de Quiero Techo me ayudó a entender qué era lo
                    que realmente quería. Me acompañó en todo el proceso: desde
                    perfilar mis intereses, hasta apoyarme en la toma de
                    decisión".
                  </p>
                </div>
              </div>

              <div
                class="card text-center mr-4 ml-4"
                style="border-color: transparent"
              >
                <div class="container mt-2 mb-2">
                  <img
                    style="
                      border-radius: 50%;
                      width: 150px;
                      height: 150px;
                      object-fit: cover;
                    "
                    src="../assets/img/alex-rec.jpg"
                    alt="Avatar"
                    class="mx-auto"
                  />
                </div>
                <div class="container">
                  <p style="color: var(--green-color); font-weight: bold">
                    Gerardo y Luisa
                  </p>
                  <p style="color: var(--black-color); margin-bottom: 10px">
                    Inversionistas
                  </p>
                  <p>
                    "La asesora de Quiero Techo nos ayudó a vender la casa que
                    teníamos y a encontrar un departamento que tuviera las
                    necesidades y comodidades que requerimos".
                  </p>
                </div>
              </div>

              <div
                class="card text-center mr-4 ml-4"
                style="border-color: transparent"
              >
                <div class="container mt-2 mb-2">
                  <img
                    style="
                      border-radius: 50%;
                      width: 150px;
                      height: 150px;
                      object-fit: cover;
                    "
                    src="../assets/img/ketut-rec.jpg"
                    alt="Avatar"
                    class="mx-auto"
                  />
                </div>
                <div class="container">
                  <p style="color: var(--green-color); font-weight: bold">
                    Marlhen y Dan
                  </p>
                  <p style="color: var(--black-color); margin-bottom: 10px">
                    Inversionistas
                  </p>
                  <p>
                    "En menos de un mes pudimos tomar la decisión. El asesor nos
                    ayudó incluso en el proceso del crédito, firma del contrato
                    con el propietario, y a conseguir un arquitecto para
                    remodelar el departamento".
                  </p>
                </div>
              </div>

              <div
                class="card text-center mr-4 ml-4"
                style="border-color: transparent"
              >
                <div class="container mt-2 mb-2">
                  <img
                    style="
                      border-radius: 50%;
                      width: 150px;
                      height: 150px;
                      object-fit: cover;
                    "
                    src="../assets/img/andrea-rec.jpg"
                    alt="Avatar"
                    class="mx-auto"
                  />
                </div>
                <div class="container">
                  <p style="color: var(--green-color); font-weight: bold">
                    Regina
                  </p>
                  <p style="color: var(--black-color); margin-bottom: 10px">
                    Propietaria
                  </p>
                  <p>
                    "Los compradores que vienen de parte de Quiero Techo vienen
                    con mucha información de mercado y claridad de lo que
                    quieren. Eso nos facilita mucho el cierre de la
                    negociación".
                  </p>
                </div>
              </div>
            </carousel>
          </div>
          <div class="col-12 text-center pt-3">
            <button
              onclick="location.href='https://wa.me/525613739050'"
              type="button"
              class="mb-5"
              style="
                color: var(--white-color);
                font-size: 1rem;
                background-color: var(--main-color);
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 15px;
                padding-left: 15px;
                border-radius: 10px;
                border-color: transparent;
                font-weight: bold;
              "
            >
              Contáctanos
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="cd">
        <div class="">
          <div class="row secondView m-0">
            <div class="col-md-12">
              <div class="container">
                <div class="row">
                  <div class="col-12 mt-5 mb-4 text-center">
                    <h2 style="font-weight: bold">
                      Quiénes confían en nosotros
                    </h2>
                  </div>

                  <div class="col-12 pb-4">
                    <div class="container">
                      <div class="row mx-auto">
                        <div
                          class="col-md-3 col-6 pt-4 pb-4 text-center my-auto"
                        >
                          <img
                            src="../assets/col-logos/logo_col1.png"
                            alt=""
                            style="width: 50%"
                          />
                        </div>
                        <div
                          class="col-md-3 col-6 pt-4 pb-4 text-center my-auto"
                        >
                          <img
                            src="../assets/col-logos/logo_col2.png"
                            alt=""
                            style="width: 50%"
                          />
                        </div>
                        <div
                          class="col-md-3 col-6 pt-4 pb-4 text-center my-auto"
                        >
                          <img
                            src="../assets/col-logos/logo_col3.png"
                            alt=""
                            style="width: 50%"
                          />
                        </div>
                        <div
                          class="col-md-3 col-6 pt-4 pb-4 text-center my-auto"
                        >
                          <img
                            src="../assets/col-logos/logo_col4.png"
                            alt=""
                            style="width: 50%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "vue-carousel-card/styles/index.css";
import carousel from "vue-owl-carousel";
import APIRequest from "../helperjs/api.js";
import Map from "@/components/map/GoogleMapMain.vue";

export default {
  name: "Home",
  components: {
    carousel,
    Map,
  },
  data() {
    return {
      districts: [],
    };
  },
  beforeMount() {
    if (this.storage.listOfFavorites == "") {
      this.storage.listOfFavorites = [];
    }
    this.storage.searchForSaleProperties = true;
    this.storage.searchForRentProperties = false;
    this.storage.searchForDistrictProperties = false;
    this.storage.districtIdToQuery = 0;
    this.storage.showNameList = "Busca por colonia";
    this.storage.listForMaps = [];
    this.storage.centerForMapLat = 0;
    this.storage.centerForMapLon = 0;
    this.storage.detailsToShowId = 1;
    this.storage.indexToSearch = 0;
    this.storage.minPrice = 3000;
    this.storage.maxPrice = 100000000;
    this.storage.rooms = 0;
    this.storage.toilets = 0;
    this.storage.rentSaleOption = true;
    this.storage.queryByFilter = false;

    APIRequest.getDistrictsToSearch().then((response) => {
      this.districts = response.data;
    });
  },
  methods: {
    SearchByWord(districtId, wordToShow) {
      this.storage.searchForSaleProperties = false;
      this.storage.searchForRentProperties = false;
      this.storage.searchForDistrictProperties = true;
      this.storage.districtIdToQuery = districtId;
      this.storage.showNameList = wordToShow;

      this.$router.replace("/list");
    },
    RouteToSale() {
      this.storage.searchForSaleProperties = true;
      this.storage.searchForRentProperties = false;
      this.storage.searchForDistrictProperties = false;
      this.storage.districtIdToQuery = 0;
      this.storage.rentSaleOption = true;

      this.$router.replace("/list");
    },
    RouteToRent() {
      this.storage.searchForSaleProperties = false;
      this.storage.searchForRentProperties = true;
      this.storage.searchForDistrictProperties = false;
      this.storage.districtIdToQuery = 0;
      this.storage.rentSaleOption = false;

      this.$router.replace("/list");
    },
  },
  computed: {
    backImage() {
      return {
        backgroundImage: `url(${require("../assets/img/bg-home.png")})`,
      };
    },
    howImage() {
      return {
        backgroundImage: `url(${require("../assets/img/nubes_background.svg")})`,
      };
    },
    recommendImage() {
      return {
        backgroundImage: `url(${require("../assets/img/background_edificios.svg")})`,
      };
    },
  },
};
</script>

<style scoped>
@import "../assets/css/global.css";
@import "../assets/css/reset.css";
@import "../assets/css/style.css";

#home-land {
  padding-top: 5rem;
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
}
.background-home {
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.buttons-main {
  background-color: var(--main-color);
  border-color: transparent;
  font-size: 0.9rem;
  color: white;
  border-radius: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 30px;
  padding-right: 30px;
  width: 150px;
}
.buttons-main:hover {
  background-color: white;
  border-color: var(--main-color);
  color: var(--main-color);
}
.buttons-main-inactive {
  background-color: var(--white-color);
  border-color: black;
  border-width: 3px;
  font-size: 0.9rem;
  color: black;
  border-radius: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 30px;
  padding-right: 30px;
  width: 150px;
}
.buttons-main-inactive:hover {
  background-color: var(--main-color);
  border-color: transparent;
  color: white;
}
.card-steps {
  padding-bottom: 1.2rem;
  background-color: white;
  border-radius: 40px;
  -moz-box-shadow: 6px 8px 14px 0px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 6px 8px 14px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 6px 8px 14px 0px rgba(0, 0, 0, 0.13);
  border-color: transparent;
}
.search-inside {
  width: 25px;
  position: absolute;
  top: 6px;
  left: 20px;
  z-index: 20;
}
.newButton {
  background-color: white;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 30px;
  padding-right: 30px;
  width: 200px;
  border-color: var(--main-color);
  font-size: 1.2rem;
  color: var(--main-color);
  border-radius: 10px;
  font-weight: bold;
}
.newButton:hover {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: white;
}
.a {
  fill: var(--main-color);
}
</style>
