<template>
  <div id="nav-bar">
    <nav class="navbar">
      <div class="container" style="z-index:1039;">
        <div class="row mx-auto w100">
          <div class="col-lg-3 col-md-12 d-none d-md-block my-auto">
            <a class="navbar-brand" href="/">
              <img
                src="@/assets/img/logo.svg"
                class="d-inline align-top"
                alt=""
                style="width:100%"
              />
            </a>
          </div>

          <b-sidebar
            id="identity"
            backdrop
            shadow
            width="100vw"
            style="position: sticky; top: 0; z-index: 99999;"
          >
            <div class="">
              <div class="container pl-0 pr-0">
                <div class="row mx-auto">
                  <div class="col-12">
                    <span v-show="true">
                      <div class="container">
                        <div class="row">
                          <div class="col-12 d-flex">
                            <router-link
                              class="mx-auto"
                              to="/calculator"
                              style="color: var(--green-color); font-size: 1.5rem; font-weight: bold;"
                              >Calcula tu crédito</router-link
                            >
                          </div>

                          <div class="col-12">
                            <hr />
                          </div>

                          <div class="col-12 d-flex">
                            <router-link
                              class="mx-auto"
                              to="/favorites"
                              style="color: var(--green-color); font-size: 1.5rem; font-weight: bold; display: inline-flex"
                            >
                              Comparar
                              <p
                                style="background-color:var(--main-color); color: white; text-align:center; font-size: 1.4rem; padding-left: 15px; padding-right: 15px; margin-left: 10px; border-radius: 10px"
                              >
                                {{ numberOfFavs }}
                              </p>
                            </router-link>
                          </div>

                          <div class="col-12">
                            <hr />
                          </div>

                          <div class="col-12 d-flex">
                            <router-link
                              class="mx-auto"
                              to="/blog"
                              style="color: var(--green-color); font-size: 1.5rem; font-weight: bold;"
                              >Blog</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </b-sidebar>

          <div
            class="col-lg-4 col-md-12 offset-lg-5 offset-md-0 pt-md-3 d-none d-md-block my-auto"
          >
            <div class="container d-flex">
              <button v-on:click="redirectCalculator()" class="buttons-main">
                Calcula tu crédito
              </button>
              <button v-on:click="favs()" class="buttons-main">
                Comparar
              </button>
              <button
                v-on:click="blogRedirect()"
                class="buttons-main pl-4 pr-4"
              >
                Blog
              </button>
            </div>
          </div>

          <!-- //cellphone only -->
          <div
            class="col-12 d-sm-block d-md-none"
            style="top: 0; padding:0; width:80vw"
          >
            <div>
              <div class="row">
                <div
                  class="col-9 d-sm-block d-md-none d-md-block my-auto text-left"
                >
                  <a href="/">
                    <img
                      src="@/assets/img/logo.svg"
                      alt=""
                      width="150"
                      style="margin-right:auto; margin-left:auto"
                    />
                  </a>
                </div>

                <div
                  class="col-2 d-sm-block d-md-none d-md-block my-auto text-right"
                >
                  <button
                    v-b-toggle.identity
                    style="background-color: transparent; border-color: transparent;"
                  >
                    <img
                      src="@/assets/img/hm-icon.svg"
                      class="align-center logo"
                      alt=""
                      width="30"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  name: "NavBar",
  methods: {
    redirectCalculator() {
      this.$router.push("/calculator");
    },
    favs() {
      this.$router.replace("/favorites");
    },
    blogRedirect() {
      this.$router.replace("/blog");
    },
  },
  computed: {
    numberOfFavs() {
      var count = 0;

      for (var i in this.storage.userLocalFavorites) {
        if (this.storage.userLocalFavorites[i] >= 1000) {
        } else {
          count++;
        }
      }
      return count;
    },
  },
};
</script>

<style scoped>
@import "../assets/css/global.css";
.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1039;
}
.buttons-main {
  background-color: var(--main-color);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-color: transparent;
  font-size: 0.9rem;
  color: white;
  border-radius: 10px;
}
.buttons-main:hover {
  background-color: white;
  border-color: var(--main-color);
  color: var(--main-color);
}
@media screen and (max-width: 979px) /* Tablet */ {
  img {
    height: 2rem;
  }
}
@media screen and (max-width: 650px) and (orientation: portrait) /* Mobile */ {
  img {
    height: 2rem;
  }
}
@media screen and (max-width: 820px) and (orientation: landscape) /* Mobile */ {
  img {
    height: 1.8rem;
  }
}

input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 3px solid var(--main-color);
  font-size: 16px;
  margin-bottom: 2rem;
  text-align: left;
  padding: 10px;
}
input:focus {
  box-shadow: 3px 3px transparent;
}
::placeholder {
  color: var(--black-color);
  opacity: 1;
}
.buttons-google {
  background-color: var(--white-color) !important;
  border-color: var(--main-color) !important;
  border-width: 2px;
  border-radius: 10px;
}
.options-container li {
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
}
.options-container label,
.options-container input {
  text-align: center;
  display: block;
}
.options-container input[type="radio"] {
  opacity: 0;
  z-index: 100;
}
.options-container input[type="radio"]:checked + label,
.Checked + label {
  background: var(--main-color);
  color: var(--white-color);
  border-radius: 40px;
}
.options-container label {
  padding: 5px;
  position: absolute;
  top: 0;
  width: 100px;
  border: 1px solid var(--main-color);
  border-radius: 40px;
  color: var(--main-color);
  cursor: pointer;
  z-index: 90;
}
.options-container label:hover {
  background: var(--main-color);
  opacity: 0.8;
  color: var(--white-color);
}
.options-container {
  list-style-type: none;
}
</style>
