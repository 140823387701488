import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import "./plugins/bootstrap-vue";
import {
  BIconFileEarmarkSpreadsheetFill,
  BootstrapVue,
  IconsPlugin,
} from "bootstrap-vue";
import App from "./App.vue";
// import './registerServiceWorker'
import VueBootstrapToasts from "vue-bootstrap-toasts";
import router from "./router";
// import store from "./store";
import ReactiveStorage from "vue-reactive-localstorage";
import axios from "axios";
import VueAxios from "vue-axios";
import firebase from "firebase";
import VueSocialSharing from "vue-social-sharing";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(ReactiveStorage, {
  userLocalFavorites: "",
  searchForSaleProperties: false,
  searchForRentProperties: false,
  searchForDistrictProperties: false,
  showNameList: "Busca por colonia",
  listForMaps: "",
  districtIdToQuery: 0,
  centerForMapLat: 0,
  centerForMapLon: 0,
  detailsToShowId: 1,
  indexToSearch: 0,
  minPrice: 0,
  maxPrice: 1000000000,
  rooms: 0,
  toilets: 0,
  rentSaleOption: true,
  queryByFilter: false,
});

var firebaseConfig = {
  apiKey: "AIzaSyChuG76tgTRa553M7Q21FONEKB6mKRCScQ",
  authDomain: "test-pa-ffd4b.firebaseapp.com",
  projectId: "test-pa-ffd4b",
  storageBucket: "test-pa-ffd4b.appspot.com",
  messagingSenderId: "807857122547",
  appId: "1:807857122547:web:56aa85dae1ae2cac085b86",
};
//import VueGmaps from "vue-gmaps";
//Vue.use(VueGmaps, {
  // key: 'AIzaSyChuG76tgTRa553M7Q21FONEKB6mKRCScQ'
  //key: "AIzaSyBx-pjWVVXoiMNn_4SJk14kM5DtLQuQwqU",
//});

firebase.initializeApp(firebaseConfig);

Vue.use(VueAxios, axios);
Vue.use(VueBootstrapToasts);
Vue.use(VueSocialSharing);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC7_S0w4SHFnpyYChhDbAhLUHxdQUOToBo",
    libraries: "places",
  },
});

import Toast from "vue-toast-plugin";
Vue.use(Toast);

new Vue({
  router,
  // store,
  render: (h) => h(App),
}).$mount("#app");

//AIzaSyC7_S0w4SHFnpyYChhDbAhLUHxdQUOToBo
//AIzaSyCEgEgtOv6QXpS-U1EpP-QrVgTAtv5EgGc