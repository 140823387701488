<template>
  <div id="app">
    <Navbar />
    <div class="main pb-5">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <Footer />

    <div class="float">
      <a target="_blank" href="https://wa.me/525613739050">
        <img
          src="@/assets/img/whatsapp-float.svg"
          alt=""
          class="float"
          width="70"
        />
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Microsoft New Tai Lue";
  src: url("~@/assets/fonts/ntailu.ttf") format("truetype");
}
#app {
  margin: 0%;
  padding: 0%;
  flex: 1;
  min-height: 100vh;
  max-width: 100vw;
}
.main {
  margin: 0%;
  padding: 0%;
  // background-image: url("~@/assets/img/background.svg");
  min-height: 100vh;
  max-width: 100vw;

  // background-attachment: fixed;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: transparent;
}
body {
  display: flex;
  flex-direction: column;
  font-family: "Microsoft New Tai Lue" !important;
  margin: 0;
  padding: 0;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-x: hidden; //horizontal
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.carousel-card-mask {
  background-color: transparent !important;
}
.carousel-card-arrow {
  background-color: var(--green-color) !important;
}
.vdp-datepicker__calendar {
  margin-right: auto;
  margin-left: auto;
}
.modal-lg {
  max-height: 60vh;
}
@media screen and (max-width: 650px) and (orientation: portrait) /* Mobile */ {

  a[class^="share-network-"] {
    margin: 0.1rem 0.3rem 0.1rem 0.3rem !important;
  }
}
.float {
  animation: float 3s ease-in-out infinite;
  position: fixed;
  bottom: 15px;
  right: 8px;
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(5px);
  }
  100% {
    transform: translatey(0px);
  }
}
.modal-footer {
  border-color: transparent;
  height: 0px;
  padding: 0px;
}
.modal-header {
  border-color: transparent;
  padding: 0px;
  z-index: 99999 !important;
}
.owl-theme .owl-dots .owl-dot span {
  height: 20px !important;
  width: 20px !important;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  height: 20px !important;
  width: 20px !important;
  background: var(--green-color) !important;
}
input[type="search"]::placeholder {
  color: var(--grey-color);
  font-weight: bolder;
  text-align: left;
}
input[type="search"] {
  border-color: var(--black-color);
  border-width: 2px;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  padding-left: 35px;
}
::placeholder {
  color: var(--black-color);
  opacity: 1;
}
.btn-secondary {
  background-color: var(--white-color);
  border-color: transparent;
  border-radius: 10px 10px 10px 10px;
  color: var(--black-color);
}
.btn-secondary:hover {
  background-color: var(--white-color);
  border-color: transparent;
  border-radius: 10px 10px 10px 10px;
  color: var(--black-color);
}
.btn-secondary:active {
  background-color: var(--white-color);
  border-color: transparent;
  border-radius: 10px 10px 10px 10px;
  color: var(--black-color);
}
.btn-secondary:focus {
  background-color: var(--white-color);
  border-color: transparent;
  border-radius: 10px 10px 10px 10px;
  color: var(--black-color);
}
.points-up {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 6rem;
}
.points-down {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 6rem;
}
.background-home {
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
input[type="checkbox"] {
  filter: invert(60%) hue-rotate(18deg) brightness(1.7);
}
.btn-primary {
  background-color: var(--main-color);
  border-color: transparent;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.btn-primary:hover {
  background-color: white;
  border-color: var(--main-color);
  border-width: 2px;
  color: var(--main-color);
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: white;
  border-color: var(--main-color);
  border-width: 2px;
  color: var(--main-color);
}
.btn-primary.focus,
.btn-primary:focus {
  background-color: white;
  border-color: var(--main-color);
  border-width: 2px;
  color: var(--main-color);
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: white;
  border-color: var(--main-color);
  border-width: 2px;
  color: var(--main-color);
}
.btn-secondary.focus,
.btn-secondary:focus {
  background-color: white;
  border-color: var(--main-color);
  border-width: 2px;
  color: var(--main-color);
}
.dropdown-item:hover {
  background-color: var(--main-color);
  color: white;
  -moz-box-shadow: 6px 8px 14px 0px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 6px 8px 14px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 6px 8px 14px 0px rgba(0, 0, 0, 0.13);
  border-radius: 0px;
}
.dropdown-menu {
  padding-right: 15px;
  padding-left: 15px;
  height: auto;
  overflow-y: auto;
  max-height: 300px;
  overflow-x: hidden;
}
.modal-content {
  border-radius: 15px;
}
#filterSide {
  background-color: var(--main-color) !important;
}
</style>
